import { Component } from 'react';
import { observer } from 'mobx-react';

import EventStore from '../../stores/EventStore';
import SwatchsStore from '../../stores/SwatchsStore';
import auth from '../../services/Auth';
import { GlobalContextTyping, Product, FlowRouteProps } from '../../types';

import FormFlowLogo from '../../utils/Component/FormFlowLogo';

import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { range, hasCurrentBlockOutDates } from '../../utils/utils';
import { getParameterByName } from '../../utils/window';
import Loading from '../../utils/Component/Loading';

import { SWATCH_SIZE } from '../utils';

import Filter from '../../shared/components/Filter';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import Swatch from './Swatch';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  selectedColors: Array<string>;
  error?: string;
  loading: boolean;
}

const emptySwatchFactory = (numberNeeded: number) =>
  range(0, numberNeeded).map(() => <Swatch key={Math.random()} selected={false} empty={true} />);

class Build extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedColors: [],
      error: undefined,
      loading: true,
    };
  }

  async componentDidMount() {
    if (auth.signedIn()) {
      await EventStore.loadEvent(window.gt.user.primaryEventId);
    }
    await SwatchsStore.loadSwatches();
    const swatchParams = getParameterByName('swatches');
    if (swatchParams) {
      SwatchsStore.swatches.forEach((s) => {
        if (
          swatchParams.includes(s.sku) &&
          SwatchsStore.selectedSwatches.length < 5 &&
          !hasCurrentBlockOutDates(s.blockoutDates ?? [])
        ) {
          SwatchsStore.addSelectedSwatch(s);
        }
      });
    }
    this.updateSwatchQueryString(SwatchsStore.generateSelectedSwatchesSkus().slice(0, 5));
    this.setState({ loading: false });
  }

  handleSubmit = () => {
    if (SwatchsStore.selectedSwatches.length > 0) {
      this.nextPage();
    } else {
      this.setState({
        error: 'Please select at least one swatch.',
      });
    }
  };

  updateSwatchQueryString = (swatchSkus: Array<string>) => {
    let params = new URLSearchParams(this.props.location.search);
    params.set('swatches', swatchSkus.join());
    const replaceByUrl = `${this.props.location.pathname}?${params.toString()}`;
    this.props.history.replace(replaceByUrl);
  };

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.onExit();
  };

  selectSwatch = (swatch: Product) => {
    this.setState({
      error: undefined,
    });
    if (SwatchsStore.selectedSwatches.length < 6) {
      const found = SwatchsStore.selectedSwatches.find((s, i) => {
        if (s.sku === swatch.sku) {
          this.removeSelectedSwatch(i);
          return true;
        }
        return false;
      });
      if (!found && SwatchsStore.selectedSwatches.length < 5) {
        SwatchsStore.addSelectedSwatch(swatch);
      }
    }
    this.updateSwatchQueryString(SwatchsStore.generateSelectedSwatchesSkus().slice(0, 5));
  };

  removeSelectedSwatch = (index: number) => {
    SwatchsStore.deleteSelectedSwatch(index);
  };

  selectColor = (color: string) => {
    if (this.state.selectedColors.find((c) => c.toLowerCase() === color.toLowerCase())) {
      this.setState((state) => ({
        selectedColors: state.selectedColors.filter((c) => c.toLowerCase() !== color.toLowerCase()),
      }));
    } else {
      this.setState((state) => ({
        selectedColors: state.selectedColors.concat(color),
      }));
    }
  };

  onExit = () => {
    let outflowIndex = 0;

    if (auth.signedIn()) {
      if (!window.gt.user.primaryEventId) {
        outflowIndex = 2;
      } else if (window.gt.user.firstName === '' || window.gt.user.lastName === '') {
        outflowIndex = 3;
      } else {
        outflowIndex = 1;
      }
    }

    this.props.flow!(EventStore.event.id ? `?eventId=${EventStore.event.id}` : undefined, outflowIndex);
  };

  renderColorFilters = () =>
    SwatchsStore.swatchColors.map((colorFilter) => (
      <Filter
        className={`tracker-filter-swatch-${colorFilter}-200619-115147 mb-8 mr-8`}
        filterColor={colorFilter}
        filterLabel={colorFilter}
        id={`filter-${colorFilter}`}
        isActive={this.state.selectedColors.includes(colorFilter)}
        isGrayBackground
        key={colorFilter}
        onChange={() => this.selectColor(colorFilter)}
      />
    ));

  renderSelectedSwatches = () => (
    <div className="fixed bottom-0 left-0 right-0 z-[900] bg-white px-0 py-16 shadow-2xl translate-z-0">
      <div className="container">
        <div className="row flex-wrap">
          <div className="col-span-12 col-start-1 sm:col-span-8 sm:col-start-2">
            <div className="row">
              <div className="col-span-12">
                <h3 className="text-h4 mb-8">My Swatches</h3>
                <div className="mb-16 flex-row">
                  <div className="grid max-w-[312px] grid-cols-5 gap-[8px]">
                    {SwatchsStore.selectedSwatches
                      .map((swatch, i) => (
                        <Swatch
                          key={swatch.id}
                          className="mb-8"
                          onClick={() => this.removeSelectedSwatch(i)}
                          swatch={swatch}
                        />
                      ))
                      .concat(emptySwatchFactory(5))
                      .slice(0, 5)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-16">
              <div className="col-span-3 sm:col-span-2">
                <button
                  className="tracker-button-swatch-back-200619-111519 btn btn-default w-full"
                  onClick={() => {
                    this.props.history.go(-1);
                  }}
                  aria-label="Back"
                >
                  <IconArrowLeft />
                </button>
              </div>

              <div className="col-span-9 sm:col-span-10">
                <button
                  className="tracker-cta-swatch-next-200619-111519 btn btn-info w-full"
                  role="link"
                  onClick={() => this.handleSubmit()}
                  disabled={SwatchsStore.selectedSwatches.length > 0 ? false : true}
                >
                  Next Step <IconArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  renderSwatches = (category: string) => (
    <div className="grid auto-rows-fr gap-16" style={{ gridTemplateColumns: `repeat(auto-fit, ${SWATCH_SIZE}px` }}>
      {SwatchsStore.swatches
        .filter((s) => {
          if (
            s.category === category &&
            (this.state.selectedColors.length === 0 || this.state.selectedColors.includes(s.color ? s.color : ''))
          ) {
            return true;
          }
          return false;
        })
        .map((swatch) => {
          const disabled = hasCurrentBlockOutDates(swatch.blockoutDates ?? []);
          return (
            <Swatch
              key={swatch.id}
              disabled={disabled}
              selected={SwatchsStore.selectedSwatches.filter((s) => s.sku === swatch.sku).length > 0}
              onClick={disabled ? () => {} : () => this.selectSwatch(swatch)}
              swatch={swatch}
            />
          );
        })}
    </div>
  );

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <FormFlowLogo />

        {this.renderSelectedSwatches()}

        <>
          <div className="container mb-128 pb-128">
            <div className="row mt-8 flex-wrap">
              <div className="col-span-12 col-start-1 sm:col-span-10 sm:col-start-2">
                <h2 className="normal-case text-h2-display mb-32">Select swatches</h2>
                <div className="row mb-32">
                  <div className="col-span-12">
                    <h4 className="text-h6 mb-4">Color</h4>
                    <div className="flex flex-row flex-wrap">{this.renderColorFilters()}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-span-12">
                    <div className="mb-32">
                      <h3 className="text-h4 mb-8">Suit & Tuxedo</h3>
                      {this.renderSwatches('Swatch (suit/tux)')}
                    </div>
                    <div className="mb-32">
                      <h3 className="text-h4 mb-8">Accessories</h3>
                      {this.renderSwatches('Swatch (accessory)')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Build)));
