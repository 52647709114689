import React from 'react';
import { Item } from '../types';
import { getSwatchStackImageUrl } from '../utils/utils';

type SwatchStackProps = {
  items: Item[];
  swatchSize?: number;
  swatchSpacing?: number;
  maxSwatches?: number;
};

type Props = React.ComponentPropsWithoutRef<'div'> & SwatchStackProps;

const swatchImages = (items: Item[], swatchSize: number, swatchSpacing: number): React.ReactNode[] => {
  const images: React.ReactNode[] = [];

  for (let i = 0; i < items.length; i++) {
    images.push(
      <img
        className="absolute"
        style={{ right: i * swatchSpacing, bottom: i * swatchSpacing, zIndex: i }}
        src={getSwatchStackImageUrl(items[i].swatch!, swatchSize)}
        alt="stacked swatch"
        loading="lazy"
        key={i}
      />
    );
  }
  return images;
};

const categoryOrder = ['Tie', 'Pocket Square', 'Vest', 'Jacket', 'Pant', 'preconfigured'];

const SwatchStack = ({
  items,
  swatchSize = 60,
  swatchSpacing = 3,
  maxSwatches = 3,
  className,
  ...props
}: Props): JSX.Element => {
  const filteredItems = items.filter((item) => item.swatch);

  const sortedItems = filteredItems.sort((current, next) =>
    categoryOrder.indexOf(current.category!) > categoryOrder.indexOf(next.category!) ? -1 : 1
  );

  const limitedItems = sortedItems.slice(-maxSwatches, sortedItems.length);

  return (
    <div
      className={className ? className : 'relative'}
      style={{
        height: swatchSize + swatchSpacing * (maxSwatches - 1),
        width: swatchSize + swatchSpacing * (maxSwatches - 1),
      }}
      {...props}
      data-testid="swatch-stack"
    >
      {swatchImages(limitedItems, swatchSize, swatchSpacing)}
    </div>
  );
};

export default SwatchStack;
