import React from 'react';
import { EventRouteProps } from '../../types';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import Loading from '../Component/Loading';
import auth from '../../services/Auth';
import LookStore from '../../stores/LookStore';
import { lookIsEmpty, itemIsBlocked, outfitWithoutBlockedItems } from '../utils';
import PreviewStore from '../../stores/look-builder/PreviewStore';

interface State {
  loading: boolean;
}

const EmptyOutfitsRedirect = <P extends EventRouteProps<any>>(WrappedComponent: React.ComponentType<P>) =>
  class EmptyOutfitsRedirect extends React.Component<P, State> {
    constructor(props: P) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    handleRedirect = async () => {
      const emptyLook = LookStore.looks.find(
        (r) => r.name!.toLowerCase().indexOf('non-participant') === -1 && lookIsEmpty(r)
      );

      if (emptyLook) {
        return this.props.history.push(
          `/event-flow/looks/build/${emptyLook.id}?sidecar=gallery&category=jacket-and-pants&eventId=${this.props.eventId}`
        );
      } else {
        try {
          const look = await LookStore.addLook(this.props.eventId, LookStore.nextOutfitName());
          if (look && look.id) {
            this.props.history.push(
              `/event-flow/looks/build/${look.id}?sidecar=gallery&category=jacket-and-pants&eventId=${this.props.eventId}`
            );
          } else {
            throw new Error('There was an error adding look.');
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    async componentDidMount() {
      if (auth.signedIn()) {
        CustomerStore.loadCustomer(auth.user());
      }

      if (!EventStore.event.id) {
        try {
          await EventStore.loadEvent(this.props.eventId);
        } catch (e) {
          var errorMessage = 'Failed to mount component';

          if (e instanceof Error) {
            errorMessage = e.message;
          }

          if (errorMessage.includes('GtEvents matching query does not exist')) {
            return this.props.history.push(`/account/events`);
          } else {
            throw new Error(errorMessage);
          }
        }
      }

      const outfit = window.localStorage.getItem('outfit');

      // if removing blocked items empties the look, redirect to builder
      if (outfit && JSON.parse(outfit!).outfit && outfitWithoutBlockedItems(JSON.parse(outfit!).outfit).length === 0) {
        window.localStorage.removeItem('outfit');
        PreviewStore.reset();
        await this.handleRedirect();
      }

      if (
        (EventStore.hasOnlyEmptyLooks() && (outfit === '' || outfit === null)) ||
        (EventStore.hasOnlyEmptyLooks() && outfit && itemIsBlocked(JSON.parse(outfit).outfit[0]))
      ) {
        PreviewStore.reset();
        await this.handleRedirect();
      } else {
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      return this.state.loading ? <Loading /> : <WrappedComponent {...this.props} />;
    }
  };

export default EmptyOutfitsRedirect;
