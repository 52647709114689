import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import type { Item } from '../../../../types';
import { useLookBuilderState } from '../context';
import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import { itemHasRetailBundle } from '../../../../utils/utils';
import { isOptionDisabled } from '../../../utils/utils';

import ItemHeader from './GalleryItemHeader';
import SeeMore from './GalleryItemSeeMoreBtn';
import IconCheck from '../../../../components/IconCheck';
import Dots from '../../../../components/Dots';

type ConfirmProps = {
  buttonSize?: string;
  imageCount?: number;
  isDetailsView?: boolean;
  item: Item;
  setAnimateDirection?: Dispatch<SetStateAction<string>>;
};

type RetailBundleOptionProps = {
  item: Item;
  radioSelection: string;
};

const RetailBundleOptions = ({ item, radioSelection }: RetailBundleOptionProps) => (
  <>
    <div className="flex w-full items-center justify-between">
      <div className="relative">
        <div
          className={`tranform absolute left-0 top-1/2 block h-[12px] w-[12px] -translate-y-1/2 cursor-pointer rounded-full border border-black bg-white shadow-inner ${
            radioSelection === 'Rent' ? '!bg-brand' : ''
          }`}
          style={{ boxShadow: 'inset 0 0 0 3px white' }}
        />
      </div>
      <div className="relative">
        <input
          type="radio"
          className="absolute left-0 top-0 z-[100] m-0 h-full w-full cursor-pointer appearance-none border-0 p-0"
          checked={radioSelection === 'Rent'}
          onChange={() => GalleryStore.selectGalleryItemOption('Rent', item)}
          name="rent"
        />
        <label htmlFor="rent" className={`text-sm pl-[20px] ${radioSelection === 'Rent' ? 'text-brand-darker' : ''}`}>
          Rent
        </label>
      </div>
      <Dots />
      <p
        className={`text-sm ${radioSelection === 'Rent' ? 'text-black' : 'text-gray-dark line-through'} `}
        style={{ lineHeight: 0.9 }}
      >
        ${item.cost}
      </p>
    </div>

    <div className="flex w-full items-center justify-between">
      <div className="relative">
        <div
          className={`tranform absolute left-0 top-1/2 block h-[12px] w-[12px] -translate-y-1/2 cursor-pointer rounded-full border border-black bg-white shadow-inner ${
            radioSelection === 'Buy' ? '!bg-brand' : ''
          }`}
          style={{ boxShadow: 'inset 0 0 0 3px white' }}
        />
      </div>
      <div className="relative">
        <input
          type="radio"
          className="absolute left-0 top-0 z-[100] m-0 h-full w-full cursor-pointer appearance-none border-0 p-0"
          checked={radioSelection === 'Buy'}
          onChange={() => GalleryStore.selectGalleryItemOption('Buy', item.retailBundle!)}
          name="buy"
        />
        <label htmlFor="buy" className={`text-sm pl-[20px] ${radioSelection === 'Buy' ? 'text-brand-darker' : ''}`}>
          Buy
        </label>
      </div>

      <Dots />
      <p
        className={`text-sm ${radioSelection === 'Buy' ? 'text-black' : 'text-gray-dark line-through'} `}
        style={{ lineHeight: 0.9 }}
      >
        ${item.retailBundle!.cost}
      </p>
    </div>
  </>
);

const GalleryItemConfirm = ({ buttonSize, isDetailsView = false, item, setAnimateDirection }: ConfirmProps) => {
  const { htoFlow } = useLookBuilderState();
  const radioSelection = GalleryStore.radioSelection;
  const productsAndBundle = PreviewStore.productsAndBundle;
  const sideCarCategory = SideCarStore.category;

  const handleConfirm = () => {
    setAnimateDirection && setAnimateDirection('right');
    window.scrollTo(0, 0);
    SideCarStore.set({ car: 'list', category: null, id: null });
    return;
  };

  return (
    <div id={`gallery-text-${item.id}`}>
      {!isDetailsView && (
        <>
          <ItemHeader displayName={item.displayName} className="text-xs mb-4 text-gray-dark" />
          {htoFlow && (
            <ItemHeader
              displayName={`${item.isRetail || item.type === 'Purchase' ? 'Buy ' : 'Rent '}$${item.cost}`}
              className="text-xs mb-4 text-gray-dark"
            />
          )}
          <SeeMore setAnimateDirection={setAnimateDirection} item={item} />
        </>
      )}

      {itemHasRetailBundle(item) && !htoFlow ? (
        <>
          <div className="py-4">
            <RetailBundleOptions item={item} radioSelection={radioSelection} />
          </div>
          <button
            className={`btn mt-8 w-full ${buttonSize === 'sm' ? 'btn-sm' : ''} ${
              PreviewStore.activeItemHasChanged ? 'btn-info' : 'btn-default'
            } tracker-btn-look_builder-set-${sideCarCategory!.replace(/-/g, '_')}-${item.id}-20200508-111100`}
            onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              return handleConfirm();
            }}
            aria-label="Confirm"
            disabled={isOptionDisabled(item, radioSelection, productsAndBundle)}
          >
            <IconCheck /> Confirm
          </button>
        </>
      ) : (
        <>
          <div className="flex justify-between">
            <p className="text-sm inline text-nowrap">
              {htoFlow ? 'Home Try-On Price' : item.isRetail ? 'Buy ' : 'Rent '}
            </p>
            <Dots />
            <p className="text-sm inline">{`$${htoFlow ? '0' : item.cost}`}</p>
          </div>

          <div className="flex flex-col flex-wrap items-center justify-center">
            <button
              className={`btn mt-8 w-full ${buttonSize === 'sm' ? 'btn-sm' : ''} ${
                PreviewStore.activeItemHasChanged ? 'btn-info' : 'btn-default'
              } tracker-btn-look_builder-set-${sideCarCategory!.replace(/-/g, '_')}-${item.id}-20200508-111100`}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                if (isDetailsView) GalleryStore.selectGalleryItem(item);
                return handleConfirm();
              }}
              aria-label="Confirm"
            >
              {htoFlow ? (
                'Add to Home Try-On'
              ) : (
                <>
                  <IconCheck /> Confirm
                </>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(GalleryItemConfirm);
