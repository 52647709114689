import { observable, action, runInAction } from 'mobx';
import { Look, Item, Member, PotentialMember } from '../types';
import { lookDeleted, lookisDeletable } from '../utils/metrics';
import { numericStringSort } from '../utils/utils';
import { addRole, updateOutfitItems, getLook, deleteLook, updateOutfit } from '../services/Events';
import MemberStore from './MemberStore';
import { productsAdded } from '../utils/metrics';
import auth from '../services/Auth';

class LookStore {
  @observable looks: Look[] = [];

  findLook = (id: string) => this.looks.find((l) => l.id === id);

  findLookByName = (name: string) => this.looks.find((l) => l.name === name);

  @action
  setMemberOnLook = (member: Member, id: string) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return { ...l, members: l.members!.concat(member) };
      } else {
        return l;
      }
    }));

  @action
  setPotentialMemberOnLook = (potentialMember: PotentialMember, id: string) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return { ...l, potentialMembers: l.potentialMembers!.concat(potentialMember) };
      } else {
        return l;
      }
    }));

  @action updatePotentialMemberOnLook = (potentialMember: PotentialMember, id: string) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return {
          ...l,
          potentialMembers: l.potentialMembers!.map((m) => {
            if (potentialMember.id === m.id) {
              return potentialMember;
            } else {
              return m;
            }
          }),
        };
      } else {
        return l;
      }
    }));

  @action
  removeMemberFromLook = (memberId: string, id: string) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return { ...l, members: l.members!.filter((m: Member) => m.id !== memberId) };
      } else {
        return l;
      }
    }));

  @action
  removePotentialMemberFromLook = (potentialMemberId: string, id: string) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return { ...l, potentialMembers: l.potentialMembers!.filter((m) => m.id !== potentialMemberId) };
      } else {
        return l;
      }
    }));

  @action
  setLooks = (looks: Look[]) => (this.looks = looks);

  addLook = async (eventId: string, name: string) => {
    try {
      const res = await addRole(eventId, name);
      if (res.status !== 201) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      const resLook = await getLook(data.id!);
      if (resLook.status !== 200) {
        throw new Error(resLook.statusText);
      }
      const fetchedLook = await resLook.json();

      if (fetchedLook.errors) {
        throw new Error(fetchedLook.errors[0].message);
      }

      runInAction(() => {
        this.looks = this.looks.concat(fetchedLook.data.role);
      });

      return fetchedLook.data.role;
    } catch (e) {
      var errorMessage = 'Failed to add look.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  addItemsToLook = async (look: Look, outfit: Array<Item>) => {
    try {
      const bundleItems = outfit.filter((item) => item.category === 'preconfigured');
      const items = outfit.filter((item) => item.category !== 'preconfigured');

      const res = await updateOutfitItems(
        look.id,
        MemberStore.getSignedInMember()!.id!,
        items.map((i) => i.id),
        [],
        bundleItems.length > 0 ? bundleItems[0]?.id : undefined
      );

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const resLook = await getLook(look.id!);
      if (resLook.status !== 200) {
        throw new Error(resLook.statusText);
      }
      const fetchedLook = await resLook.json();

      if (fetchedLook.errors) {
        throw new Error(fetchedLook.errors[0].message);
      }

      MemberStore.updateLookOnMembers(fetchedLook.data.role);

      try {
        if (fetchedLook.members?.length) {
          const itemIds = outfit.map((product) => product.id);

          productsAdded(auth.user(), itemIds);
        }
      } catch (e) {
        console.error(e);
      }

      runInAction(() => {
        this.looks = this.looks.map((l: Look) => {
          if (l.id === look.id) {
            return fetchedLook.data.role;
          } else {
            return l;
          }
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to add items to look.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  updateLookName = async (eventId: string, roleId: string, roleName: string) => {
    try {
      const result = await updateOutfit(eventId, roleId, roleName);
      if (result.status !== 200) {
        throw new Error(result.statusText);
      }

      runInAction(() => {
        this.looks = this.looks.map((l: Look) => {
          if (l.id === roleId) {
            return { ...l, name: roleName };
          } else {
            return l;
          }
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to update look name.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action updateMemberIsPaidOnLook = (memberId: string, id: string, isPaid: boolean) =>
    (this.looks = this.looks.map((l: Look) => {
      if (l.id === id) {
        return {
          ...l,
          members: l.members!.map((m) => {
            if (memberId === m.id) {
              return { ...m, isPaid };
            } else {
              return m;
            }
          }),
        };
      } else {
        return l;
      }
    }));

  updateOutfitItems = async (
    lookId: string,
    productsToAdd: number[],
    productsToRemove: number[],
    bundleToAdd?: number,
    bundleToRemove?: number
  ) => {
    try {
      const res = await updateOutfitItems(
        lookId,
        MemberStore.getSignedInMember()!.id!,
        productsToAdd,
        productsToRemove,
        bundleToAdd,
        bundleToRemove
      );

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const updatedLook = await res.json();

      if (updatedLook.errors) {
        throw new Error(updatedLook.errors[0].message);
      }

      const resLook = await getLook(lookId);

      if (resLook.status !== 200) {
        throw new Error(resLook.statusText);
      }
      const fetchedLook = await resLook.json();

      if (fetchedLook.errors) {
        throw new Error(fetchedLook.errors[0].message);
      }

      MemberStore.updateLookOnMembers(fetchedLook.data.role);

      try {
        if (productsToAdd && fetchedLook.members?.length) {
          const itemIds = productsToAdd.concat(bundleToAdd ?? []);

          productsAdded(auth.user(), itemIds);
        }
      } catch (e) {
        console.error(e);
      }

      runInAction(() => {
        this.looks = this.looks.map((l: Look) => {
          if (l.id === lookId) {
            return fetchedLook.data.role;
          } else {
            return l;
          }
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to update outfit items.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  addLookAndItems = async (eventId: string, outfit: Array<Item>) => {
    const newLookName = this.nextOutfitName();
    await this.addLook(eventId, newLookName);

    const newLook = this.looks.find((l: Look) => l.name === newLookName);

    if (newLook) {
      await this.addItemsToLook(newLook, outfit);
    } else {
      throw new Error('Newly created look not found');
    }

    return newLook;
  };

  getSignedInMemberLook = () =>
    this.looks.find((l: Look) => {
      if (l.members!.find((m: Member) => m.accountId === window.gt.user.id)) {
        return true;
      } else {
        return false;
      }
    });

  deleteLook = async (lookId: string) => {
    try {
      const result = await deleteLook(lookId);

      if (result.status !== 200) {
        throw new Error(result.statusText);
      }

      const resJson = await result.json();
      if (resJson.errors) {
        throw new Error(resJson.errors[0].message);
      }

      runInAction(() => {
        this.looks = this.looks.filter((l: Look) => l.id !== lookId);
      });
    } catch (e) {
      var errorMessage = 'Failed to delete look.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  deleteEmptyLook = async (lookId: string) => {
    lookDeleted();

    await this.deleteLook(lookId);
  };

  deleteEmptyLooks = async () => {
    await Promise.all(
      this.looks!.map(async (look) => {
        if (lookisDeletable(look)) {
          await this.deleteEmptyLook(look.id);
          return true;
        }
        return false;
      })
    );
  };

  duplicateLook = async (eventId: string, outfit: Array<Item>) => {
    try {
      const dupeLook = await this.addLookAndItems(eventId, outfit);

      return dupeLook.id;
    } catch (e) {
      console.error(e);
      throw new Error('Error duplicating look');
    }
  };

  nextOutfitName() {
    // Match current look names e.g ["Look 1", "Look 3", "Look 2"]
    // Find highest number and return it plus 1
    const numLooks = this.looks
      .filter(
        (l) =>
          l.name!.toLowerCase().indexOf('non-participant') === -1 &&
          /\d/.test(l.name!) &&
          l.name!.toLowerCase().includes('look')
      )
      .map((l) => l.name!)
      .sort((a, b) => {
        return numericStringSort(a, b);
      });

    if (numLooks && numLooks.length === 0) {
      return 'Look 1';
    }

    if (numLooks.slice(-1)[0].match(/\d+/) === null) {
      return 'Look 1';
    }

    return `Look ${Number(numLooks.slice(-1)[0].match(/\d+/)![0]) + 1}`;
  }

  @action reset = () => (this.looks = []);
}

const singleton = new LookStore();
export default singleton;
