import React, { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { PotentialMember, MemberCard as MemberCardType } from '../../../../types';
import PotentialMemberStore from '../../../../stores/PotentialMemberStore';
import LookStore from '../../../../stores/LookStore';
import EventStore from '../../../../stores/EventStore';
import MemberStore from '../../../../stores/MemberStore';
import PartyRoleStore from '../../../../stores/PartyRoleStore';
import { observer } from 'mobx-react';
import {
  isNonParticipant,
  isNonParticipantLook,
  sortLooks,
  lookIsEmpty,
  hasNonCancelledStandardOrders,
} from '../../../../utils/utils';
import IconTrashcan from '../../../../components/IconTrashcan';
import IconLock from '../../../../components/IconLock';
import IconX from '../../../../components/IconX';
import IconCheck from '../../../../components/IconCheck';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import { participantAdded, participantRemoved } from '../../../../utils/metrics';

export type ComponentProps = {
  card: MemberCardType;
  handleDelete: (index: number) => void;
  cardIndex: number;
};

const MemberCard = (props: ComponentProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showLockMessage, setLockMessage] = useState(false);
  const [tempName, setTempName] = useState(props.card.nickname);
  const [id, setId] = useState(props.card.id);
  const [tempRoleName, setTempRoleName] = useState(props.card.partyRoleName);
  const [isPlaceHolder, setIsPlaceHolder] = useState(props.card.isPlaceHolder);

  const isNameUnique = (newPotentialName: string) => {
    if (
      PotentialMemberStore.potentialMembers.filter(
        (m: PotentialMember) =>
          m.id !== id && !isNonParticipant(m) && m.nickname!.toLowerCase() === newPotentialName.toLowerCase()
      ).length > 0
    ) {
      return false;
    }

    return true;
  };

  const validatePotentialName = async (value: string) => {
    setError(null);
    const trimmedValue = value.trim();
    setTempName(trimmedValue);

    // prevent empty chars
    if (!(trimmedValue.length > 0)) {
      if (!isPlaceHolder) {
        setError('name cannot be empty');
      } else {
        setError(null);
      }

      setTempName('');
      setIsActive(false);
      return;
    }

    if (!isNameUnique(trimmedValue)) {
      if (!isSaving) {
        setIsActive(false);
        setError('name already used');
        return;
      }
      return;
    }

    if (!isSaving) {
      setError(null);
      setTempName(trimmedValue);
      const partyRole = PartyRoleStore.partyRoles.find((p) => p.name.toLowerCase() === tempRoleName.toLowerCase())!;
      if (isPlaceHolder) {
        try {
          setIsSaving(() => true);
          const newMember = await PotentialMemberStore.add({
            nickname: trimmedValue,
            partyRoleId: partyRole.id,
            roleId: LookStore.looks
              .filter((look) => !isNonParticipantLook(look) && !lookIsEmpty(look))
              .sort(sortLooks)[0].id!,
            eventId: EventStore.event.id!,
          });
          setId(newMember.id);
          setIsPlaceHolder(false);

          participantAdded(EventStore.event, newMember);
        } catch (e) {
          setIsSaving(() => false);
          setError('there was a problem adding this member');
        }
      } else {
        try {
          setIsSaving(() => true);
          await PotentialMemberStore.update(id, {
            nickname: trimmedValue,
            partyRoleId: partyRole.id,
          });
        } catch (e) {
          setIsSaving(() => false);
          setError('there was a problem updating this member');
        }
      }
    }
    setIsSaving(() => false);
    setIsActive(false);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.type === 'blur') {
      validatePotentialName(tempName);
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      target.blur();
    }
  };

  const editPartyRole = async (name: string) => {
    setTempRoleName(name);
    if (!isPlaceHolder) {
      try {
        await PotentialMemberStore.update(id, {
          partyRoleId: PartyRoleStore.partyRoles.find((p) => p.name.toLowerCase() === name.toLowerCase())!.id,
        });
      } catch (e) {
        setError('there was a problem updating this member');
      }
    }
  };

  const deleteMember = () => {
    props.handleDelete(props.cardIndex);
    if (!isPlaceHolder) {
      if (props.card.member === null) {
        PotentialMemberStore.delete(id);
      } else {
        MemberStore.delete(props.card.member!.id!);
      }

      const potentialMember = PotentialMemberStore.potentialMembers.find(
        (m: PotentialMember) => m.id !== id && !isNonParticipant(m)
      );

      participantRemoved(potentialMember, props.card.member!);
    }
  };

  return (
    <div className="my-32 grid grid-cols-11 gap-x-8">
      {!confirmDelete && !showLockMessage && (
        <>
          <div className="col-span-5 flex items-center">
            {props.card.isLocked ? (
              <p>{tempName}</p>
            ) : (
              <div style={{ flexGrow: 1, position: 'relative' }}>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <FormInput
                    name={`${id!}_input`}
                    placeholder="full name"
                    value={tempName}
                    errorMessage={error ? error : ''}
                    required
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleBlur(e)}
                    onFocus={() => setIsActive(true)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTempName(e.target.value)}
                    hideIcon
                    className="w-full"
                    disabled={isSaving}
                  />
                  <Transition
                    as={Fragment}
                    show={isActive}
                    enter="transition duration-200"
                    enterFrom="opacity-0 translate-x-100"
                    enterTo="opacity-100"
                    leave="transition duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0 scale-0"
                  >
                    <button
                      aria-label="Save"
                      className="tracker-btn-member_card-save-200619-104118 btn btn-sm btn-info absolute bottom-12 right-12 top-12 px-8 py-0"
                    >
                      {isSaving ? undefined : <IconCheck />} {isSaving && `Saving...`}
                    </button>
                  </Transition>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-5">
            <FormSelect
              className="w-full"
              disabled={props.card.isPaid}
              defaultValue={tempRoleName}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => editPartyRole(e.target.value)}
            >
              {PartyRoleStore.partyRoles
                .filter((role) => role.isRenting && EventStore.gtEventType!.id === role.gtEventType.id)
                .map((role) => (
                  <option key={role.name} value={role.name}>
                    {role.name}
                  </option>
                ))}
            </FormSelect>
          </div>
          <div className="col-span-1 flex items-stretch justify-center">
            {!props.card.isLocked ? (
              <button
                onClick={() => (tempName === '' ? deleteMember() : setConfirmDelete(true))}
                className="tracker-cta-members-delete_member-20191029-133544 btn btn-sm w-full px-0"
                aria-label="Delete"
              >
                <IconTrashcan />
              </button>
            ) : (
              <button
                onClick={() => setLockMessage(true)}
                aria-label="Locked"
                className="tracker-cta-members-lock_message-200619-104153 btn btn-sm w-full px-0"
              >
                <IconLock />
              </button>
            )}
          </div>
        </>
      )}
      {confirmDelete && (
        <>
          <div className="col-span-full flex flex-col items-center space-y-16 bg-white p-32">
            <p className="text-center">Are you sure you want to delete {tempName}?</p>
            <div className="flex gap-x-8">
              <button
                onClick={() => deleteMember()}
                className="tracker-btn-roles-confirm_delete-200619-104217 btn btn-sm btn-danger"
              >
                Yes, Delete {tempName}
              </button>
              <button
                onClick={() => setConfirmDelete(false)}
                className="tracker-btn-roles-cancel_delete-200619-104217 btn btn-sm btn-default-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {showLockMessage && (
        <>
          <div className="col-span-12">
            <div className="flex items-center justify-around border border-gray-light p-16 pr-8">
              <p className="text-sm grow text-center">
                {(() => {
                  if (hasNonCancelledStandardOrders(MemberStore.members.find((m) => m.id === props.card.member!.id)!)) {
                    return 'Once a member has paid, their info is no longer editable.';
                  }

                  if (props.card.member && props.card.member.isCreator) {
                    return 'Event creator cannot be deleted.';
                  }
                })()}
              </p>
              <button
                onClick={() => setLockMessage(false)}
                aria-label="Close"
                className="tracker-btn-roles-close-200619-104217 btn btn-sm ml-auto"
              >
                <IconX />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(MemberCard);
