import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import Nav from '../../look-builder/components/Nav';
import { itemIsBlocked, scrollTop } from '../../utils/utils';
import ItemStore from '../../stores/ItemStore';
import Loading from '../../utils/Component/Loading';
import { getTwoLooksForEachBundleType, RandomizedLook, RandomLookBySwatch } from '../utils/looksBySwatch';
import { Item } from '../../types';
import { Link } from 'react-router-dom';
import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';
import windowUtils from '../../utils/window';
import { trackCustomizationOfRandomLook } from '../../utils/metrics';
import SwatchComponent from '../components/SwatchComponent';
import Line from '../../components/Line';
import { Transition } from '@headlessui/react';
import { pageFadeIn } from '../../utils/Component/Animations';

interface Props extends RouteComponentProps<any> {}

interface State {
  loading: boolean;
  swatch: Item | undefined;
  recommendedLooks: RandomizedLook[];
}

class Recommendations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      swatch: undefined,
      recommendedLooks: [],
    };
  }

  async componentDidMount() {
    if (!ItemStore.itemsFetched) {
      try {
        await ItemStore.fetchAndCache();
      } catch (e) {
        console.error(e);
        throw Error('Something Went Wrong');
      }
    }

    this.generateLooks();
  }

  generateLooks = () => {
    const algorithm = new RandomLookBySwatch();

    const swatch = ItemStore.cachedItemsByCategory['Swatch (accessory)'].find(
      (s) => s.id === Number(this.props.match.params.swatchId)
    )!;

    this.setState({
      loading: false,
      swatch,
      recommendedLooks: getTwoLooksForEachBundleType(
        algorithm,
        swatch,
        ItemStore.cachedProducts,
        ItemStore.cachedBundles.filter(
          (bundle) =>
            bundle.isRetail === false &&
            itemIsBlocked(bundle) === false &&
            bundle.displayName?.includes('RECOMMENDED') !== true
        )
      ),
    });

    scrollTop();
  };

  getSubheading() {
    if (this.state.recommendedLooks.length > 0) {
      return (
        <p className="text-sm">
          Here are some stylish combinations to go with your color selection. You can save any of these looks or use
          them as a starting point to perfect your&nbsp;look.
        </p>
      );
    }

    return (
      <p>
        There are no recommendations set up for this color yet. Please{' '}
        <Link
          to="#"
          onClick={() => this.props.history.push(`/customize/swatch/browse${this.props.location.search}`)}
          className={`tracker-link-recommendation-try_another-20210610-105025`}
        >
          try a different color
        </Link>{' '}
        or check back another&nbsp;time.
      </p>
    );
  }

  render() {
    const looks = this.state.recommendedLooks;
    const swatch = ItemStore.cachedItemsByCategory['Swatch (accessory)'].find(
      (s) => s.id === Number(this.props.match.params.swatchId)
    )!;

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <>
        <Nav />

        <Transition {...pageFadeIn}>
          <div className="container">
            <div className="row items-center py-64 md:py-128">
              <div className="order-last col-span-12 xs:order-first xs:col-span-8 sm:col-span-7 md:col-span-6 md:col-start-2 lg:col-span-5 lg:col-start-3">
                <h2 className="text-h4 text-gray-dark">{this.state.swatch!.displayName}</h2>

                <h3 className="text-h2-display">
                  {looks.length > 0 ? 'Look Recommendations' : 'Sorry, no recommendations'}
                </h3>

                <Line />

                {this.getSubheading()}
              </div>

              <div className="col-span-12 flex justify-end xs:col-start-9 sm:col-span-3 sm:col-start-10 sm:justify-start md:col-span-2 md:col-start-9 lg:col-span-2 lg:col-start-9">
                <div className="max-w-[152px] flex-grow sm:max-w-none">
                  <Transition
                    appear
                    show
                    enter="transition duration-500"
                    enterFrom="opacity-0 translate-x-[-70%]"
                    enterTo="opacity-100"
                  >
                    <SwatchComponent
                      swatch={swatch}
                      to={`/customize/swatch/browse${this.props.location.search}`}
                      linkText="Change Color"
                      iconLeft={true}
                      className={`tracker-btn-recommendation-change-20210610-105148`}
                    />
                  </Transition>
                </div>
              </div>
            </div>
          </div>

          {looks.length > 0 && (
            <div className="container space-y-32">
              <div className="grid grid-cols-2 gap-32 md:grid-cols-4">
                {looks.map((look, index) => (
                  <div className="relative origin-center" key={index + Date.now().toString()}>
                    <div className="relative bg-white p-16">
                      <div className="look-image-group relative z-10 flex items-start justify-center px-8">
                        <LookPreview items={[...look.items!, ...look.bundles!]} />
                      </div>

                      <div className="flex flex-col">
                        <button
                          onClick={() => {
                            trackCustomizationOfRandomLook(look.bundles[0], look.items);
                            windowUtils.updateProductsAndBundleQueryString([...look.items!, ...look.bundles!]);
                            this.props.history.push(`/customize${window.location.search}`);
                          }}
                          className={`tracker-btn-recommendation-save-20210610-110434 btn btn-sm btn-info flex-grow`}
                        >
                          Customize
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col">
                <button
                  onClick={() => {
                    this.generateLooks();
                  }}
                  className={`tracker-btn-recommendation-show_new_looks-20210610-110409 btn btn-default-outline flex-grow`}
                >
                  Show New Looks
                </button>
              </div>
            </div>
          )}
        </Transition>
      </>
    );
  }
}

export default observer(Recommendations);
